import { Form, FormInstance, FormProps } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import React, { createContext, Dispatch, FC, SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ValidateMessages } from 'types/Form'
import IReport from 'types/IReport'

import { ReportFormData } from './right/ReportCreationFormRight'

interface ReportCreationContextProps {
  /**
   * Флаг, указывающий наличие ошибок валидации
   */
  hasValidateErrors: boolean
  /**
   * Инстанс формы
   */
  form: FormInstance<ReportFormData>
  /**
   * Обработчик изменения значений полей формы.
   */
  onValuesChange: FormProps<ReportFormData>['onValuesChange']
  /**
   * Опциональное начальное заключение, которое может быть частично заполнено и используется для предзаполнения формы.
   */
  initialReport: Partial<IReport>
  /**
   * Флаг, указывающий видимость модального окна
   */
  isActionModalVisible: boolean
  /**
   * Функция для изменения видимости модального окна
   */
  setIsActionModalVisible: Dispatch<SetStateAction<boolean>>
  /**
   * Флаг, указывающий наличие конфликта
   */
  isConflictError: boolean
  /**
   * Функция для изменения наличия конфликта
   */
  setIsConflictError: Dispatch<SetStateAction<boolean>>
  /**
   * Сообщение об ошибке при сохранении заключения
   */
  saveReportError: string
  /**
   * Функция для изменения сообщения об ошибке при сохранении
   */
  setSaveReportError: Dispatch<SetStateAction<string>>
  /**
   * Сообщение валидации заключения
   */
  validateMessages: ValidateMessages
}

const ReportCreationContext = createContext<ReportCreationContextProps | null>(null)

export const useReportCreationContext = () => {
  const context = useContext(ReportCreationContext)

  if (!context) {
    throw new Error()
  }

  return context
}

const ReportCreationContextProvider: FC = ({ children }) => {
  const [hasValidateErrors, setHasValidateErrors] = useState<boolean>(false)
  const isGemotest = useLisModeAsGemotest()
  const [form] = Form.useForm<ReportFormData>()
  const initialReport = useTypedSelector((state) => state.reports.creationModalInitialReport) ?? {}
  const { t } = useTranslation()

  const [isActionModalVisible, setIsActionModalVisible] = useState<boolean>(false)
  const [isConflictError, setIsConflictError] = useState<boolean>(false)
  const [saveReportError, setSaveReportError] = useState<string>('')

  const validateMessages: ValidateMessages = {
    required: t('Поле не должно быть пустым'),
  }

  /**
   * Колбэк, вызываемый при изменении значений в форме.
   *
   * @param {Partial<ReportFormData>} changedValues - Объект с измененными полями формы и их новыми значениями.
   * @param {ReportFormData} values - Объект, содержащий все текущие значения формы.
   *
   * @description
   * Эта функция проверяет значения полей `complexity`, `icd10` и `report`.
   * Если выполняется хотя бы одно из следующих условий, устанавливает `hasValidateErrors` в `true`:
   * - Поле `report` пустое.
   * - Поле `icd10` пустое.
   * - Если флаг `isGemotest` установлен в `true`, поле `complexity` также должно быть заполнено.
   * В противном случае `hasValidateErrors` устанавливается в `false`.
   */
  const onValuesChange: FormProps<ReportFormData>['onValuesChange'] = (
    changedValues: Partial<ReportFormData>,
    values: ReportFormData,
  ) => {
    const { complexity, icd10, report } = values

    if (!report || !icd10 || (isGemotest && !complexity)) {
      setHasValidateErrors(true)
    } else {
      setHasValidateErrors(false)
    }
  }

  return (
    <ReportCreationContext.Provider
      value={{
        form,
        hasValidateErrors,
        initialReport,
        isActionModalVisible,
        isConflictError,
        onValuesChange,
        saveReportError,
        setIsActionModalVisible,
        setIsConflictError,
        setSaveReportError,
        validateMessages,
      }}
    >
      {children}
    </ReportCreationContext.Provider>
  )
}

export default ReportCreationContextProvider
