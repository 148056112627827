import FormItem from 'antd/es/form/FormItem'
import { TextAreaElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  max-width: 100%;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  overflow-y: auto;
  max-width: 100%;
  height: 100vh;

  .ant-form-item-label {
    padding: 0;
    margin-bottom: 4px;
  }

  .ant-form-item-label > label {
    color: var(--color-text-3);
    font-size: 12px;
    line-height: 16px;
  }
`

export const StyledTextArea = styled(TextAreaElement)`
  &.ant-input {
    font-size: 14px;
    line-height: 20px;
    min-height: 48px !important;
    //68px = 3 строки
    height: 68px;
    padding: 4px 8px;
    resize: vertical !important;
  }
`

export const MedicalReportFormItem = styled(FormItem)`
  .ant-col.ant-form-item-label {
    padding-bottom: 4px;

    label {
      color: var(--color-text-3);
    }

    & .ant-form-item-required {
      &::before {
        content: '';
        margin-right: 0;
      }

      &::after {
        content: ' *';
        display: block;
      }
    }
  }

  margin-bottom: 0;
`
