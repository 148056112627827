import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDIctICD10 } from 'features/dictionaries'
import { useCallback } from 'react'
import { DictionaryItem } from 'types/IDictionary'

import Icd10SelectElement from './ui/Icd10SelectElement'

type Props = {
  onChange?: (icd10?: DictionaryItem) => void
  value?: DictionaryItem
  filterByVisible?: boolean
  placeholder?: string
  multiline?: boolean
}

const Icd10SelectContainer = ({ filterByVisible = true, multiline, onChange, placeholder, value, ...props }: Props) => {
  const dictionary = useLiveQuery(() => getDataFromDB('ICD_10')) as TDIctICD10

  const { data, updatedAt } = { ...dictionary }

  const onIcd10Change = useCallback(
    (id: number) => {
      if (data) {
        const icd10 = findIcd10(data.group2Icd10Codes, id)
        if (onChange && icd10) {
          onChange(icd10)
        }
      }
    },
    [updatedAt],
  )

  return (
    <Icd10SelectElement
      groups2Icd10Codes={data?.group2Icd10Codes || {}}
      onChange={onIcd10Change}
      data-testid={'icd10input-select'}
      style={{
        width: '100%',
      }}
      icd10={value}
      onDelete={() => onChange && onChange()}
      filterByVisible={filterByVisible}
      {...props}
      multiline={multiline}
    />
  )
}

const findIcd10 = (group2Icd10Codes: Record<string, DictionaryItem[]>, id: number) =>
  Object.values(group2Icd10Codes)
    .flatMap((it) => it)
    .find((icd10) => icd10.id === id)

export default Icd10SelectContainer
