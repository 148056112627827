import { Form, RadioChangeEvent } from 'antd'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { Icd10SelectContainer } from 'entities/icd-10'
import { TumorSelectContainer } from 'entities/tumor-type'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLisModeAsGemotest } from 'shared/lib/workspaces'
import { SegmentElement } from 'shared/ui/kit'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'

const StyledFormWrapper = styled(Flex)`
  padding: 16px 16px 0;
  height: 100%;
  border-top: 1px solid var(--color-border-1);
  border-bottom: 1px solid var(--color-border-1);
`

/** Тип для значений компонента выбора категории сложности */
type IComplexityOption = {
  /** Лейбл значения категории сложности */
  label: string
  /** Значение категории сложности */
  value: number
}

interface Props {
  /**
   * Флаг, указывающий, что заключение в данный момент подписывается.
   */
  isSigning: boolean
}

const ReportCreationFormLeftBodyContainer: FC<Props> = ({ isSigning }) => {
  const { t } = useTranslation()
  const isGemotest = useLisModeAsGemotest()
  const theme = useThemeContext()
  const { form, initialReport, onValuesChange, saveReportError } = useReportCreationContext()
  const { caseMacroDescription, comment, customInfo, icd10, microDescription, morphologyCode, report } = initialReport
  const gemotestReport = customInfo || {}
  const { complexity } = gemotestReport

  const validateMessages = {
    required: t('Поле не должно быть пустым'),
  }

  const checkStringEmpty = (_: any, value: string) => {
    if (!isSigning || value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(validateMessages.required))
  }

  const checkSelectEmpty = (_: any, value: any) => {
    if (!isSigning || value?.id) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(validateMessages.required))
  }

  /** Обертка над радиогруппой. Делаем деселект для элементов */
  const onSegmentedClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()

    if (
      // @ts-ignore
      e?.target?.className?.includes('ant-radio-button-checked') ||
      // @ts-ignore
      e?.target?.parentElement?.className?.includes('ant-radio-button-wrapper-checked')
    ) {
      e.preventDefault()
      form.setFieldValue('complexity', undefined)
    }
  }

  const complexityOptions: IComplexityOption[] = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
  ]

  /** Обработчкик для контроллируемой смены значения категории сложности */
  const onSegmentedChange = (event: RadioChangeEvent) => {
    const value = event.target.value
    form.setFieldValue('complexity', value)
  }

  return (
    <StyledFormWrapper vertical justify="space-between">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          caseMacroDescription,
          comment,
          complexity,
          icd10,
          microDescription,
          morphologyCode,
          report,
        }}
        validateMessages={validateMessages}
        onValuesChange={onValuesChange}
      >
        <Flex vertical align="start" justify="start" gap={24} height="100%">
          <MedicalReportFormItem
            label={t('Категория сложности')}
            name="complexity"
            style={{ maxWidth: '100%' }}
            rules={[{ required: isGemotest, validator: isGemotest ? checkStringEmpty : () => {} }]}
          >
            <SegmentElement
              onClick={onSegmentedClick}
              options={complexityOptions}
              style={{ maxWidth: 120 }}
              colorTheme={theme.theme}
              onChange={onSegmentedChange}
              size="small"
            />
          </MedicalReportFormItem>
          <Flex
            vertical
            gap={8}
            style={{ border: '1px solid var(--color-border-1)', borderRadius: 10, padding: 8 }}
            width={376}
          >
            <MedicalReportFormItem
              label={t('МКБ-10')}
              name="icd10"
              rules={[{ required: true, validator: checkSelectEmpty }]}
            >
              <Icd10SelectContainer multiline={false} />
            </MedicalReportFormItem>
            <MedicalReportFormItem label={t('Морфологический код (МКБ-0)')} name="morphologyCode">
              <TumorSelectContainer multiline={false} />
            </MedicalReportFormItem>
          </Flex>
        </Flex>
      </Form>
      {!!saveReportError && (
        <div style={{ padding: '16px 0' }}>
          <ErrorText>{saveReportError}</ErrorText>
        </div>
      )}
    </StyledFormWrapper>
  )
}

export default ReportCreationFormLeftBodyContainer

const ErrorText = styled.div`
  background-color: var(--color-red);
  border-radius: 5px;
  padding: 8px;
  height: 34px;
`
