import { Divider } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { t } from 'i18next'
import React, { FC } from 'react'
import { ButtonElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  /** Флаг, указывающий, есть ли lisMode. */
  isLisMode: boolean
  /** Флаг, указывающий на ошибку в валидации. */
  hasErrors: boolean
  /** Флаг, указывающий, что в данный момент происходит сохранение данных. */
  isSaving: boolean
  /** Функция, вызываемая при нажатии кнопки "Вернуться в форму". */
  onCancel: () => void
  /** Функция, вызываемая при нажатии кнопки "Уйти без сохранения". */
  onDiscard: () => void
  /** Функция, вызываемая при нажатии кнопки "Сохранить" */
  onSave: () => void
}

const ActionConfirmationModal: FC<Props> = ({ hasErrors, isLisMode, isSaving, onCancel, onDiscard, onSave }) => (
  <Overlay>
    <StyledModal>
      <StyledTitle>
        {t(hasErrors ? 'Не все обязательные поля заполнены' : 'Остались несохраненные изменения')}
      </StyledTitle>
      <StyledDivider />
      <ButtonContainer>
        <StyledButton
          disabled={isSaving || (hasErrors && !isLisMode)}
          loading={isSaving}
          type="primary"
          onClick={onSave}
          style={{ marginRight: 6 }}
        >
          {isLisMode ? t('Сохранить черновик') : t('Сохранить')}
        </StyledButton>
        <StyledButton onClick={onDiscard} style={{ marginRight: 6 }}>
          {t('Уйти без сохранения')}
        </StyledButton>
        <StyledButton onClick={onCancel}>{t('Вернуться в форму')}</StyledButton>
      </ButtonContainer>
    </StyledModal>
  </Overlay>
)

export default ActionConfirmationModal

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const StyledDivider = styled(Divider)`
  margin: 16px 0 !important;
`

const StyledTitle = styled(TitleElement)`
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  line-height: 20px !important;
`

const StyledButton = styled(ButtonElement)`
  margin: 0 !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  height: 24px;
  width: 280px;
`

const ButtonContainer = styled.div`
  display: inline-grid;
  row-gap: 8px;
  width: 100%;
`

const StyledModal = styled.div`
  ${() => modalBackground}
  ${() => modalShadow}
  padding: 16px;
  border-radius: 5px;
  text-align: center;
  z-index: 1001;
`
