import { useTypedDispatch, useTypedSelector } from 'app/redux/lib/selector'
import { initDictionaries } from 'features/dictionaries'
import {
  useReportCreateMutation,
  useReportEditMutation,
  useReportRemoveMutation,
  useReportsDataQuery,
} from 'features/reports/api/query'
import { reportsSlice, selectedReportByCaseSelector } from 'features/reports/model/reportsSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { viewerPageSlice } from 'pages/viewer'
import React, { FC, RefObject, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { clearEmptyProps } from 'shared/lib/common'
import { useLisModeAsGemotest } from 'shared/lib/workspaces'
import Flex from 'shared/ui/kit/ui/Flex'
import { ModalHandle, ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components'
import ISource from 'types/ISource'

import ReportCreationFormLeftContainer from './left/ReportCreationFormLeftContainer'
import { useReportCreationContext } from './ReportCreationContext'
import ReportResizableForm from './ReportResizableForm'
import ReportCreationFormRight, { ReportFormData } from './right/ReportCreationFormRight'

export type ReportCreationModalProps = {
  caseId: number
  source: ISource
  modalRef: RefObject<ModalHandle>
}

/** Код ошибки для специальной ошибки на UI*/
const CONFLICT_SERVER_CODE = 409

const StyledModalProvider = styled(ModalProvider)`
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    .ant-modal-close {
      top: 2px;
      right: 2px;
    }
  }
`

const ReportCreationModalContainer: FC<ReportCreationModalProps> = ({ caseId, modalRef, source }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const dispatch = useTypedDispatch()
  const isGemotest = useLisModeAsGemotest()
  const creationModalInitialReport = useTypedSelector((state) => state.reports.creationModalInitialReport)
  const index = useTypedSelector(selectedReportByCaseSelector(caseId))
  const workspaceId = useCurrentWorkspaceId()

  const { data } = useReportsDataQuery(caseId, source)

  const { isLoading: isReportCreating, mutate: createMutate } = useReportCreateMutation()
  const { isLoading: isReportUpdating, mutate: editMutate } = useReportEditMutation()
  const { isLoading: isRemoveLoading, mutate: removeMutate } = useReportRemoveMutation()

  const { form, setIsActionModalVisible, setIsConflictError, setSaveReportError } = useReportCreationContext()

  const onCloseModal = useCallback(() => {
    setIsActionModalVisible(false)
    resetReportError()
    initDictionaries(String(workspaceId))
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(false))
    modalRef.current?.close()
    dispatch(reportsSlice.actions.setInitialReport(undefined))
    form.resetFields()
  }, [workspaceId])

  const onCancelModal = () => setIsActionModalVisible(true)

  const onSaveReportError = (error: any) => {
    const data = error?.response?.data
    const isConflict = data?.status === CONFLICT_SERVER_CODE
    const uiError = isConflict
      ? t('Заключение было подписано в ЛИС, редактирование невозможно')
      : String(data?.message || error)

    setIsConflictError(isConflict)
    setSaveReportError(uiError)
  }

  const resetReportError = () => {
    setIsConflictError(false)
    setSaveReportError('')
  }

  const onSuccess = () => {
    onCloseModal()
    queryClient.invalidateQueries([QUERY_TYPE.REPORT_DATA, { caseId }])
  }

  const onSave = async ({
    caseMacroDescription,
    comment,
    complexity,
    icd10,
    microDescription,
    morphologyCode,
    report,
    signed,
  }: ReportFormData) => {
    let payload = {}
    if (isGemotest) {
      payload = {
        signed,
      }
    }

    const formReport = {
      ...({ creationModalInitialReport } || {}),
      ...payload,
      caseId,
      caseMacroDescription,
      comment,
      customInfo: { complexity: complexity === null ? undefined : complexity, type: 'BASIC' },
      icd10,
      microDescription,
      morphologyCode,
      report,
    }
    // очищаем пустые поля для формы
    const clearFormReport = clearEmptyProps(formReport)

    resetReportError()
    if (creationModalInitialReport?.medicalReportId) {
      editMutate(
        {
          caseId,
          report: clearFormReport,
          reportId: creationModalInitialReport.medicalReportId,
        },
        { onError: onSaveReportError, onSuccess },
      )
    } else {
      createMutate(
        {
          ...payload,
          caseId,
          report: formReport,
        },
        {
          onError: onSaveReportError,
          onSuccess,
        },
      )
    }
  }

  const onDelete = (reportId: number) => {
    resetReportError()
    removeMutate(
      {
        caseId,
        reportId,
      },
      {
        onSuccess: () => {
          const reportsLength = data?.length || 0
          if (index + 1 > reportsLength)
            dispatch(reportsSlice.actions.setSelectedReportIndex({ caseId, index: index - 1 }))
          onSuccess()
        },
      },
    )
  }

  return (
    <StyledModalProvider
      ref={modalRef}
      destroyOnClose
      closable={false}
      onCancel={onCancelModal}
      footer={null}
      width="fit-content"
      centered={true}
      bodyStyle={{
        padding: 0,
      }}
      style={{ maxWidth: '100%', overflow: 'hidden' }}
    >
      <ReportResizableForm>
        <Flex style={{ overflow: 'hidden' }}>
          <ReportCreationFormLeftContainer
            onCloseModal={onCloseModal}
            caseId={caseId}
            onSave={onSave}
            onCancel={onCancelModal}
            onDelete={onDelete}
            isSaving={isReportCreating || isReportUpdating}
            isDeleting={isRemoveLoading}
          />
          <ReportCreationFormRight />
        </Flex>
      </ReportResizableForm>
    </StyledModalProvider>
  )
}

export default ReportCreationModalContainer
