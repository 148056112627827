import { useTypedDispatch, useTypedSelector } from 'app/redux/lib/selector'
import { reportsSlice } from 'features/reports/model/reportsSlice'
import { Resizable, ResizableProps } from 're-resizable'
import React, { FC } from 'react'

/**
 * Минимальная ширина формы создания отчета.
 */
const MIN_REPORT_FORM_WIDTH = 816

const ReportResizableForm: FC = ({ children }) => {
  const reportModalWidth = useTypedSelector((state) => state.reports.modalWidth)
  const dispatch = useTypedDispatch()

  const onResizeStop: ResizableProps['onResizeStop'] = (event, direction, elementRef) => {
    dispatch(reportsSlice.actions.setReportModalWidth(elementRef.clientWidth))
  }

  return (
    <Resizable
      resizeRatio={2}
      defaultSize={{ width: reportModalWidth }}
      minWidth={MIN_REPORT_FORM_WIDTH}
      style={{ maxWidth: '100%' }}
      onResizeStop={onResizeStop}
      enable={{
        left: true,
        right: true,
      }}
    >
      {children}
    </Resizable>
  )
}

export default ReportResizableForm
