import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { casesSlice } from 'features/cases/model/casesSlice'
import { commentsSlice } from 'features/comments/model/commentsSlice'
import dashboardSlice from 'features/dashboard/dashboardSlice'
import { reportsPersistConfig, reportsSlice } from 'features/reports/model/reportsSlice'
import { searchSlice } from 'features/search/model/searchSlice'
import { slideBlocksSlice } from 'features/thumbnails/model/BlocksSlice'
import { workspacesSlice } from 'features/workspace/model/workspacesSlice'
import { History } from 'history'
import { slideMapViewSlice } from 'pages/viewer/model/slideMapViewSlice'
import { viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { tusFilesSlice } from 'processes/tus/model/tusFilesSlice'
import { userSlice } from 'processes/user/model/userSlice'
import { createMigrate, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createViewerRootReducer } from 'viewer/container'

import { migrations } from './migrations'

const viewPagePersistConfig = {
  blacklist: ['geometryTolerance', 'sumpFilters', 'isNewSerialCuts', 'isRescan', 'isNewCuts', 'isNewStain'],
  key: 'viewPage',
  migrate: createMigrate(migrations, { debug: true }),
  storage,
  version: 21,
  whiteList: ['tools', 'isMagnetize'],
}

const getViewerPersistConfig = (id: string) => {
  const viewerPersistConfig = {
    blacklist: ['viewerHelp', 'viewerCoregistration', 'viewerAttachments', 'viewerToolsGridSlice'],
    key: id,
    storage,
  }
  return viewerPersistConfig
}

const rootReducer = (history: History) =>
  combineReducers({
    annotations: annotationsSlice.reducer,
    cases: casesSlice.reducer,
    comments: commentsSlice.reducer,
    dashboard: dashboardSlice.reducer,
    reports: persistReducer(reportsPersistConfig, reportsSlice.reducer),
    router: connectRouter(history),
    search: searchSlice.reducer,
    slideBlocks: slideBlocksSlice.reducer,
    slideMapView: slideMapViewSlice.reducer,
    tusFiles: tusFilesSlice.reducer,
    user: userSlice.reducer,
    viewerPage: persistReducer(viewPagePersistConfig, viewerPageSlice.reducer),
    viewers: combineReducers({
      A: persistReducer(getViewerPersistConfig('A'), createViewerRootReducer('A')),
      B: persistReducer(getViewerPersistConfig('B'), createViewerRootReducer('B')),
      C: persistReducer(getViewerPersistConfig('C'), createViewerRootReducer('C')),
      D: persistReducer(getViewerPersistConfig('D'), createViewerRootReducer('D')),
    }),
    workspaces: workspacesSlice.reducer,
  })

export default rootReducer
