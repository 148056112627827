import icons from 'assets'
import { IconButtonElement, IconElement, ImageElement, TooltipElement } from 'shared/ui/kit'
import HoverWithClickPopover from 'shared/ui/popover/HoverWithClickPopover'
import styled from 'styled-components'

type Props = {
  src: string
  iconName?: keyof typeof icons
  disable?: boolean
  tooltipTitle?: string
}

const PreviewContent = styled.div`
  cursor: pointer;
`

const Image = styled(ImageElement)`
  width: 256px;
  height: 256px;
`

const HoverIconButton = styled(IconButtonElement)<{ disable?: boolean }>`
  opacity: ${({ disable }) => (disable ? 0.4 : undefined)};
`

const HoverWithClickPreviewPopup = ({ disable, iconName = 'dot', src, tooltipTitle }: Props) => (
  <HoverWithClickPopover disable={disable} placement="leftBottom" content={<Image src={src} />}>
    <PreviewContent>
      <TooltipElement title={disable ? tooltipTitle : undefined} placement={'topRight'} arrowPointAtCenter={true}>
        <HoverIconButton disable={disable} icon={<IconElement name={iconName} />} />
      </TooltipElement>
    </PreviewContent>
  </HoverWithClickPopover>
)

export default HoverWithClickPreviewPopup
