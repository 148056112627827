import icons from 'assets'
import { IIconElementProps } from 'shared/ui/kit'
import styled from 'styled-components'

type Props = {
  name: IIconElementProps['name']
}

const HintWrapper = styled.div`
  width: 24px;
  height: 32px;
  /* filter: drop-shadow(0 1px 1px black); */
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.7));
`

export const MouseHint = ({ name }: Props) => {
  const mouseKey = icons[name]
  return <HintWrapper as={mouseKey} />
}
