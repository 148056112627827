import React, { FC } from 'react'

import ReportCreationContext from './ReportCreationContext'
import ReportCreationModalContainer, { ReportCreationModalProps } from './ReportCreationModalContainer'

const ReportCreationForm: FC<ReportCreationModalProps> = (props) => (
  <ReportCreationContext>
    <ReportCreationModalContainer {...props} />
  </ReportCreationContext>
)

export default ReportCreationForm
