import icons from 'assets'
import React, { FC } from 'react'
import { ButtonElement, IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledButton = styled(ButtonElement)`
  padding: 4px;
  background-color: var(--color-bg-3);
  border-radius: 5px;
`

interface Props {
  iconName: keyof typeof icons
  onClick: () => void
}

const ReportActionButton: FC<Props> = ({ iconName, onClick }) => (
  <StyledButton onClick={onClick}>
    <IconElement name={iconName} size="md" fill="var(--color-text-3)" />
  </StyledButton>
)

export default ReportActionButton
